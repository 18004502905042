"use strict";

import ml from "./js/ml";
import assert from "@brillout/assert";
import { TabSettings } from "../../tab-utils/TabSettings";
import { PRESETS } from "./PRESETS";
import { preset_concept_name } from "./js/preset_concept_name";
import { youtubeSetUrl } from "./js/notify/youtubeAlarm";
export { init_timer_settings };
/*
import {dom_beat} from './load_clock';
import {refresh_big_text_size} from '../../BigText';
*/

function init_timer_settings() {
  var tab_settings = new TabSettings({
    option_spec_list: get_option_list(),
    preset_spec_list: PRESETS,
    on_any_change: on_any_change,
    enable_import_export: true,
    subapp_id: "timer",
    preset_concept_name: preset_concept_name
  });
  var get_option_value = tab_settings.get_option_value.bind(tab_settings);
  tab_settings.generate_dom();
  return {
    get_option_value: get_option_value
  };

  function on_any_change(_ref) {
    var is_initial_run = _ref.is_initial_run;
    update_youtube_url();
    ml.htmlBackgroundListener();
  }

  function update_youtube_url() {
    var youtube_url = get_option_value("timer_youtube_alarm");
    assert(youtube_url.constructor === String, {
      youtube_url: youtube_url
    });
    youtubeSetUrl(youtube_url);
  }
}

function get_option_list() {
  return [{
    option_id: "timer_theme",
    option_type: "preset-input",
    option_description: preset_concept_name,
    option_default: "_creator"
    /*get_default_theme()*/

  }, {
    option_id: "timer_background_image",
    option_type: "background-image-input",
    option_description: "Background Image",
    option_default: "",
    option_placeholder: "Image URL",
    is_creator_option: true
  }, {
    option_id: "timer_background_color",
    option_type: "background-color-input",
    option_description: "Background Color",
    option_default: "#ffffff",
    is_creator_option: true
  }, {
    option_id: "timer_youtube_alarm",
    option_type: "text-input",
    option_description: "YouTube Alarm",
    option_default: "https://youtu.be/PS5KAEgrtMA",
    option_placeholder: "Youtube URL Address",
    is_creator_option: true
  }];
}
/*
function get_default_theme() {
  return is_dark_mode() ? "dark" : "classic";
}
*/